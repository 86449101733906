// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

#navbar-logo {
  width: $navbar-logo-width;
  min-width: $navbar-logo-min-width;
  max-width: $navbar-logo-max-width;
}

.nav-link-size {
  font-size: $nav-link-size !important;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.fadeout {
  opacity: 0;
  transition: ease-out all 0.5s;
}

